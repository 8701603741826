<template>
    <div>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Dashboard</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div class="row">
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <div class="col-lg-12 col-md-12">
                    <b-card-group deck v-if="dashboard_links">
                        <div v-for="(link, index) in dashboard_links" :key="index" class="col-lg-3 col-md-3 mb-3" v-if="Array.isArray(link.permission) ? hasAnyPermission(link.permission): hasPermission(link.permission)">
                            <b-card :bg-variant="link.variant" no-body :text-variant="link.color" class="text-center">
                                <router-link :to="link.path" style="color:#ffff;">
                                    <b-card-body>
                                        <b-card-text class="my-4">
                                            <h5>{{link.name}}</h5>
                                        </b-card-text>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                        </div>
                    </b-card-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            access: Boolean
        };
    },
    computed: {
        dashboard_links(){
            return this.$store.getters.getAppSetting("ies_portal_user_interface", "dashboard_links");
        },
    }
};
</script>
